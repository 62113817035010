import './style.scss';
import logo from '@cfra-nextgen-frontend/shared/src/assets/images/CFRA White Logo.svg';
export function CapitolInsightsEmailConfirmation() {
    // TO DO: Include following when handling logic for client verification
    // const [searchParams] = useSearchParams();
    return (
        <div className='confirmation-content-container'>
            <div className='confirmation-content'>   
                <div></div>
                <img src={logo} className="logo" alt='logo' />
            </div>
            <div>
                <h2 className='title'>Email Confirmed</h2>
            </div>
            <div className='confirmation-text'>Thank you for confirming your email address. You may close this window.</div>
        </div>
    );
}
