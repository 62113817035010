import { SubmitHandler, useForm } from 'react-hook-form';
import { companyType, countries } from './dropdownLists';
import './style.scss';
import { useEffect, useState } from 'react';
import { sendSingleRequest } from 'utils/api';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';

interface IFormData {
    firstName: string;
    lastName: string;
    email: string;
    telephone: string;
    company: string;
    companyType: string;
    country: string;
}

export function CapitolInsightsTrialForm() {
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [formData, setFormdata] = useState<IFormData | undefined>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { isDirty, isValid },
    } = useForm<IFormData>({ mode: 'onChange' });
    const onSubmit: SubmitHandler<IFormData> = (data) => {
        setDataSubmitted(true);
        setFormdata(data);
        setIsSubmitting(true);
    };
    const response = sendSingleRequest(
        {
            requestBody: formData,
            config: {
                enabled: isSubmitting,
            },
            noErrorOnNoKeyValuePairs: true,
        },
        {
            requestType: RequestTypes.POST,
            path: 'send-trial-verification-email',
            queryKeyFirstElement: 'sendTrialVerificationEmail',
            apiName: ApiNames.UserManagementUnauthenticated,
        },
    );
    useEffect(() => {
        if (!response?.isLoading && response?.data) {
            setIsSubmitting(false);
        }
    }, [response?.data, response?.isLoading]);
    return (
        <div className='capitol-insights-trial-form'>
            {dataSubmitted ? (
                <div className='verification-text-container'>
                    <h2 className='title'>Verify your e-mail.</h2>
                    <div className='confirmation-text'>
                        Please verify your email address by clicking the link sent to
                        <br />
                        <b>{formData?.email}</b>
                    </div>
                    <div className='button-container'>
                        <button className='submit-button enabled' type='submit' onClick={() => setIsSubmitting(true)}>
                            Resend
                        </button>
                    </div>
                </div>
            ) : (
                <div className='form-container'>
                    <h3 className='title'>Get Started Today.</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='form-content'>
                            <div className='input-container'>
                                <label>First Name*</label>
                                <input
                                    type='text'
                                    className='input-field'
                                    placeholder='Joe'
                                    {...register('firstName', { required: true, maxLength: 50 })}
                                />
                            </div>
                            <div className='input-container'>
                                <label>Last Name*</label>
                                <input
                                    type='text'
                                    className='input-field'
                                    placeholder='Smith'
                                    {...register('lastName', { required: true, maxLength: 50 })}
                                />
                            </div>
                            <div className='input-container'>
                                <label>Email*</label>
                                <input
                                    type='text'
                                    className='input-field'
                                    placeholder='joe.smith@acme.com'
                                    {...register('email', { required: true, maxLength: 50 })}
                                />
                            </div>
                            <div className='input-container'>
                                <label>Telephone*</label>
                                <input
                                    type='text'
                                    className='input-field'
                                    placeholder='XXX-XXX-XXXX'
                                    {...register('telephone', { required: true, maxLength: 15 })}
                                />
                            </div>
                            <div className='input-container'>
                                <label>Company Name*</label>
                                <input
                                    type='text'
                                    className='input-field'
                                    placeholder='Acme'
                                    {...register('company', { required: true, maxLength: 50 })}
                                />
                            </div>
                            <div className='input-container'>
                                <label>Company Type*</label>
                                <select
                                    className='input-field'
                                    defaultValue=''
                                    {...register('companyType', { required: true })}>
                                    <option className='default-option' value='' disabled hidden>
                                        Select
                                    </option>
                                    {companyType.map((type, index) => (
                                        <option value={type} key={index}>
                                            {type}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-container'>
                                <label>Country*</label>
                                <select
                                    className='input-field'
                                    defaultValue=''
                                    {...register('country', { required: true })}>
                                    <option className='default-option' value='' disabled hidden>
                                        Select
                                    </option>
                                    {countries.map((country, index) => (
                                        <option value={country} key={index}>
                                            {country}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='terms'>
                            By completing this Trial Request Form you hereby acknowledge and agree to the following:
                            <ul>
                                <li>
                                    Use of the CFRA Services is solely for your personal and internal evaluation
                                    purposes during the trial, and which is subject to these{' '}
                                    <a
                                        href='https://www.cfraresearch.com/terms-of-use/'
                                        target='_blank'
                                        rel='noreferrer'>
                                        terms of use
                                    </a>
                                    .
                                </li>
                                <li>
                                    The CFRA Services are provided: for informational purposes only; with no guarantee
                                    of accuracy, timeliness or completeness; are not recommendations to buy, sell, or
                                    hold any investment or security; and are without regard to any investment objectives
                                    or particular needs.
                                </li>
                                <li>
                                    You consent to the processing of your personal information submitted through this
                                    form in accordance with{' '}
                                    <a
                                        href='https://www.cfraresearch.com/privacy-policy/'
                                        target='_blank'
                                        rel='noreferrer'>
                                        CFRA's Privacy Notice
                                    </a>
                                    .
                                </li>
                            </ul>
                        </div>
                        <div className='button-container'>
                            <button
                                className={!isDirty || !isValid ? 'submit-button disabled' : 'submit-button enabled'}
                                type='submit'
                                disabled={!isDirty || !isValid}>
                                Start Trial
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
}
